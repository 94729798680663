<template>
  <div class="mt-8">
    <div class="card">
       <div class="card-body">
          <div class="text-center">
            <h2><b>{{$t('about-us')}} </b></h2>
          </div>                              
          <hr>
          <p v-if="$i18n.locale==='kin'" v-html="about.kin"></p>
          <p v-else v-html="about.eng"></p>
          <p>
            <a :href="'mailto:' +about.email">Email contact: {{about.email}}</a>
          </p>
       </div>
    </div>
  </div>
</template>
<script>

// import axios from "axios";

import axios from "axios";
export default {
  name: "about",
  components: {
  
  },
  data() {
    return {
      about: {},
    };
  },
  methods: {
    get_about: function () {
        axios
          .get(
               `${process.env.VUE_APP_BACKEND_URL}/api/about/`
            )
            .then((res) => {
                this.about = res.data;
            })
              .catch(() => {
            });
    },
            
  },
  created() {
    this.get_about()
  },
};
</script>

<style>
</style>
